.create-group-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  margin-top: 30px;
}

div.admin-group-list {
  margin: 10px;
}

div.admin-group {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  width: 150px;
}

p.admin-group-name {
  color: white;
  font-size: 16px;
}

img.admin-group-logo {
  width: 15px;
  height: 15px;
  padding: 10px 5px 10px 5px;
}

div.group-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
