div.tournament {
  width: calc(100% - 40px);
  margin: 80px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  div.welcome-container {
    display: flex;
    flex-direction: column;
    color: white;
    background-color: $color-medium-grey;
    width: calc(100% - 40px);
    border-radius: 20px;
    padding: 20px 20px 30px 20px;
    font-weight: 500;

    h1 {
      font-size: 16px;
      color: #ff6b00;
    }
    p {
      font-size: 12px;
      line-height: 17px;
    }
  }

  h2.subheader {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
  }

  div.rules {
    width: 100%;
    margin-top: 40px;
    a {
      border: 1px solid #ff6b00;
      color: #ff6b00;
      padding: 8px 12px;
      border-radius: 12px;
      text-decoration: none;
      font-size: 12px;
      font-weight: 600;
    }
  }

  div.sponsors {
    width: calc(100% - 0px);
    margin-top: 40px;

    div.sponsors-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;

      div.sponsor {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        height: 120px;
        border-radius: 18px;
        margin-top: 20px;
        margin-bottom: 30px;
        width: 50%;

        img {
          max-width: 150px;
          max-height: 100px;
        }
      }
    }
  }
}
