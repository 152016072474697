$font-size: 20px;

.slideshow-table {
  width: 100%;
  max-width: 40%;
  margin: 0px 30px;
  border-radius: 10px;
  background-color: $color-medium-grey;
  padding: 5px 0px;

  .table-content {
    margin: 0px 10px;

    .header {
      border-bottom: 1px solid grey;
      margin-bottom: 10px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      color: white;
      margin: 40px 20px;

      .order-column {
        color: $color-text-grey;
        margin: 0px 8px 0px 0px;
        font-size: $font-size;
      }

      .club-column {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 6px;
        font-size: $font-size;

        img {
          max-width: $font-size + 5px;
          max-height: $font-size + 5px;
          width: 100%;
          height: 100%;
          margin-left: 20px;
        }

        .group-name {
          color: $color-text-grey;
          margin-left: 6px;
          margin: 0;
          width: 100%;
          font-size: $font-size;
        }

        .team-name {
          display: inline;
          margin-left: 20px;
          width: 100%;
          min-width: 200px;
          color: $color-text-grey;
          font-size: $font-size;
        }
      }

      .points-column {
        display: flex;

        > p {
          margin: 0px 20px;
          font-size: $font-size;
          text-align: center;
          width: 21px;
          color: $color-text-grey;
        }
      }
    }
  }
}

.slideshow-matches {
  width: 40%;
  margin: 0px 30px;

  .slideshow-match {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background-color: $color-dark-grey;
    color: $color-light-grey;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    margin: 10px 0px;

    .column-0 {
      border-radius: 0 7.5px 7.5px 0;
      content: "";
      display: block;
      height: calc(100% - 18px);
      position: absolute;
      top: 10px;
      width: 5px;
      background-color: $color-green;
    }

    .column-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      min-width: 170px;
      white-space: nowrap;
      overflow: hidden;

      .team {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;

        img {
          max-height: $font-size;
          max-width: $font-size;
          margin: $font-size;
        }

        span {
          font-size: $font-size;
          white-space: nowrap;
        }
      }
    }

    .column-4 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50px;
      margin: 0px 15px;
      height: 80%;

      .point {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        font-size: $font-size;
        color: white;
      }
    }
  }
}
