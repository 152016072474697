.table {
  width: 100%;
  max-width: 400px;
  margin: 15px 0px;
  border-radius: 10px;
  background-color: $color-medium-grey;
  padding: 5px 0px;

  .header {
  }

  .table-content {
    margin: 0px 10px;

    .header {
      border-bottom: 1px solid grey;
      margin-bottom: 10px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      color: white;

      .order-column {
        color: $color-text-grey;
        margin: 0px 8px 0px 0px;
      }

      .club-column {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 6px;

        img {
          max-width: 15px;
          max-height: 15px;
          width: 100%;
          height: 100%;
        }

        .group-name {
          color: $color-text-grey;
          margin-left: 6px;
          width: 100%;
        }

        .team-name {
          display: inline;
          margin-left: 6px;
          width: 100%;
          min-width: 100px;
          color: $color-text-grey;
        }
      }

      .points-column {
        display: flex;

        > p {
          font-size: 10px;
          text-align: center;
          width: 21px;
          color: $color-text-grey;
        }
      }
    }
  }
}

.table-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding-top: 50px;
}

.table-button-group-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  background-color: #111111;
  width: 100%;
}
