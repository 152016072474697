div.front-page-container {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 1)),
    url("../../assets/frontpage-image3.png");
  height: 100vh;
  width: 100vw;

  div.frontpage-content-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    padding: 15% 0px;

    div.dates-container {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: 30px;

      div.tournament-row {
        display: flex;
        margin: 10px;

        a {
          display: flex;
          align-items: center;
        }

        p.tournament-text {
          font-weight: 600;
        }
      }
    }
  }
}