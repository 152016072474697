div.top-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

div.team-row {
  width: 100%;
  display: flex;
  align-items: center;

  img {
    padding-right: 10px;
  }
}
